<template>
  <div class="ribbon" v-if="isRibbonShown">
    <div class="ribbon__text">{{ ribbon.ribbon_text }}</div>
    <div class="ribbon__btn" @click="onClick()" v-if="!!ribbon.button_text">{{ ribbon.button_text }}</div>
    <button type="button" class="ribbon__close" @click="closeRibbon" aria-label="close">
      <svg-icon name="close" class="ribbon__close-ico"></svg-icon>
    </button>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

export default {
  props: {
    isRibbonShown: Boolean,
    closeRibbon: Function,
  },
  data() {
    return {
      ribbon: {}
    }
  },
  computed:{
    ...mapState({
      ribbonState:state => state.ribbon
    })
  },
  methods: {
    ...mapMutations({
      updateRibbon: "home/updateRibbon"
    }),
    onClick() {
      window.open(this.ribbon.button_url, '_blank')
    }
  },
  mounted() {
    if(!this.ribbonState){
      window.onload = () => {
        this.$axios.$get('promotions/top_banners/active').then((ribbon) => {
          this.ribbon = ribbon;
          this.updateRibbon(ribbon);
        }).catch(e => {
          this.error = e.data.error;
        });
      }
    }
  }
}

</script>

<style>

  .ribbon {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px 40px 6px 8px;
    height: 28px;
    background-color: var(--brand-color);
    transform: translate3d(0, 0, 0);
    
  }

  .ribbon__text {
    margin-right: 16px;
    font-size: 0.75rem;
    font-weight: 600;
    font-family: var(--secondary-font);
    color: #FFFFFF;
    text-align: left;
  }

  .ribbon__btn {
    padding: 8px 15px;
    color: var(--brand-color);
    font-size: 0.65rem;
    font-weight: bold;
    background-color: #FFFFFF;
    text-transform: uppercase;
    white-space: nowrap;
    cursor: pointer;

    @media (--viewport-tablet) {
      font-size: 0.75rem;
    }
  }

  .ribbon__close {
    position: absolute;
    top: 45%;
    right: 8px;
    transform: translateX(-50%) translateY(-50%);
    fill: #FFFFFF;
    cursor: pointer;
  }

  .ribbon__close, .ribbon__close-ico {
    width: 12px;
    height: 13px;
    @media (--viewport-tablet) {
      width: 14px;
      height: 14px;
    }
  }

</style>
