import debounce from 'lodash/debounce';
import { mapActions } from 'vuex';

import { TYPES } from '@/utils/types/autocomplete-link';

export const searchBarMixin = {
  data() {
    return {
      results: [],
      search: '',
      isLoading: false
    };
  },
  methods: {
    ...mapActions({
      fetchProducts: 'search/fetchProducts',
      fetchUrlProducts: 'search/fetchUrlProducts',
    }),
    fetchResults: function () {
      this.isLoading = true;
      this.results = [];
      this.debouncedSearch ||= debounce(async () => {
        this.$axios.$get('search?q=' + encodeURIComponent(this.search)).then((result) => {
          this.results = result;
          this.isLoading = false;
        });
      }, 500);
      this.debouncedSearch();
    },
    goToTheSearchPage(searchTerm, searchUrl = null) { 
      if(this.$route.path == searchUrl){
        if (searchTerm) {
          this.$router.push(
            // { path: searchUrl, query: searchTerm } ,
            this.$route, { name: searchUrl, query: { query: searchTerm } },
            () => { this.fetchUrlProducts({ route: this.$route, query: this.$route.query }); }
          );
        }
      } else{
        if (this.search) {
          const searchParams = new URLSearchParams({ query: this.search });
          this.$router.push(
            this.$transformParamsForPort(this.$route, { name: 'search', query: { query: searchParams.get('query') } }),
            () => { this.fetchProducts({ route: this.$route, query: this.$route.query }); }
          );
        }
      }
    },
    goToTheResultPage(result) {
      let path;
      switch(result.type) {
        case TYPES.PRODUCT:
          path = '/product/' + result.source.slug;
          break;
        case TYPES.CATEGORY:
          path = '/catalog/' + result.source.slug;
          break;
        case TYPES.PRODUCT_LIST:
          path = '/search/' + encodeURIComponent(this.search);
          break;
        case TYPES.MANUFACTURER:
          path = '/brand/' + result.source.slug;
          break;  
        default:
          path = '/product/' + result.slug;
          break; 
      }
      this.$router.push(this.$transformParamsForPort(this.$route, { path: path }));
    }
  },
};
