<template>
  <nav class="catalog-navigation">
    <div class="catalog-navigation__wrapper wrapper">
      <div v-if="shownCategories" class="catalog-navigation__list" @mouseleave="hoveredCategory = null">
        <div class="left_nav">
          <nuxt-link class="who_we_are" :to="{ name: 'page-slug', params: { slug: 'about-yachtneeds' }}">
            {{$t('who-we-are')}}
          </nuxt-link>
          <nuxt-link v-for="category in shownCategories" :key="category.id" :to="$transformParamsForPort($route, {
              name: 'catalog-slug',
              params: { slug: category.slug }
            })" class="catalog-navigation__item">
            <svg-icon class="catalog-navigation__ico" :name="'home-' + category.slug.toLowerCase()"></svg-icon>
            <div class="dropdown">
              <p class="catalog-navigation__text" @mouseover="isMobile ? null : showDropdown(category.name)"
                @mouseleave="hideDropdown" v-if="category.name != 'Other'">
                {{ category.name }}
              </p>
            </div>
          </nuxt-link>
          <div v-if="showHover" v-show="isDropdownVisible" class="dropdown-content" @mouseover="showDropdownlist" @mouseleave="hideDropdown">
            <div class="hoverData">
              <div class="categoryImage" ref="catalogNavigation">
                <img src="../../../assets/images/bridge-background.webp" alt="Bridge image" width="610" height="120"
                  v-show="desiredName === 'Bridge'" loading="lazy" />
                <img src="../../../assets/images/deck-background.webp" alt="Deck image" width="610" height="120"
                  v-show="desiredName === 'Deck'" loading="lazy" />
                <img src="../../../assets/images/eng-background.webp" alt="Engineering image" width="610" height="120"
                  v-show="desiredName === 'Engineering'" loading="lazy" />
                <img src="../../../assets/images/bg-catalog-catalog.webp" alt="Engineering image" width="610" height="120"
                  v-show="desiredName === 'Other'" loading="lazy" />
                <img src="../../../assets/images/galley-background.webp" alt="Galley image" width="610" height="120"
                  v-show="desiredName === 'Galley' || desiredName === 'Interior'" loading="lazy" />
                <p class="category-name">{{ categoryName.name }}</p>
              </div>
              <div class="categories-data" v-if="catLength !== 0">
                <div class="categories-list">
                  <div class="main-category">
                    <div class="main-category-heading">
                      <h1 class="category-main-heading">{{$t('catalog-category-main-heading')}}</h1>
                    </div>
                  </div>
                  <ul>
                    <li v-for="(subcategory, index) in categoryName.subcategories" :key="index"
                    :class="{ active: activeIndex === index }" @mouseover="getSubCategory(subcategory.name, index)">
                    <nuxt-link :to="$transformParamsForPort($route, {
                        name: 'catalog-slug',
                        params: { slug: subcategory.slug }
                      })
                        " class="catalog-navigation__dropdown">
                            {{ subcategory.name }}
                            <svg-icon name="arrow-down" class="product-ico"></svg-icon>
                    </nuxt-link>
                    </li>
                  </ul>
                </div>
                <div class="sub-categories">
                  <div class="main-sub-category">
                    <div class="sub-category-heading">
                      <h1 class="category-main-heading">{{ subCategoryName }}</h1>
                    </div>
                    <ul>
                      <li v-for="sub in selectsubCategories" :key="sub.id">
                        <nuxt-link :to="$transformParamsForPort($route, {
                          name: 'catalog-slug',
                          params: { slug: sub.slug }
                        })
                          " class="category-item__link" @click="hideDropdown">
                          {{ sub.name }}
                        </nuxt-link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="categories-data" v-else>
                <div class="categories-list">
                  <div class="main-category">
                    <div class="main-category-heading">
                      <h1 class="category-main-heading">{{$t('catalog-category-heading-no-data-found')}}</h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
       <div class="right_nav">
        <div class="trustpilot-micro-review" v-if="reviewsStatus && !isMobile">
          <div  id="headertrustbox" class="trustpilot-widget" data-locale="en-US" data-template-id="5419b732fbfb950b10de65e5" data-businessunit-id="64beae5111c8af68008b74d2" data-style-height="35px" data-style-width="100%" data-theme="dark">
            <a href="https://www.trustpilot.com/review/yachtneeds.com" target="_blank" rel="noopener">Trustpilot</a>
          </div>
        </div>
        <nuxt-link class="become_a_seller" :to="{ name: 'page-slug', params: { slug: 'become-a-seller' }}">
          {{ $t('home-partners-become-a-seller') }}
        </nuxt-link>
       </div>
      </div>
    </div>
    <div class="trustpilot-micro-mobile-review" v-if="reviewsStatus && isMobile">
        <div  id="headertrustbox" class="trustpilot-widget" data-locale="en-US" data-template-id="5419b732fbfb950b10de65e5" data-businessunit-id="64beae5111c8af68008b74d2" data-style-height="24px" data-style-width="100%" data-theme="dark">
          <a href="https://www.trustpilot.com/review/yachtneeds.com" target="_blank" rel="noopener">Trustpilot</a>
        </div>
    </div>
  </nav>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import debounce from 'lodash/debounce';

export default {
  data() {
    return {
      hoveredCategory: null,
      activeIndex: 0,
      isDropdownVisible: false,
      isMobile: false,
      categoryName: {},
      subCategoryName: "",
      selectsubCategories: [],
      catLength: "",
      desiredName: "",
      showHover:false
    };
  },
  computed: {
    ...mapGetters({
      shownCategories: "shared/shownCategories"
    }),
    ...mapState({
        reviewsStatus: state => state.home.reviewsStatus,
      }),
  },
  mounted() {
    this.checkMobile();
    window.addEventListener('resize', debounce(this.checkMobile, 200));
    setTimeout(() => {
      const headtrustbox = document.getElementById('headertrustbox');
      if(headtrustbox) window.Trustpilot?.loadFromElement(headtrustbox);
    }, 100);
  },
  methods: {
    getName(name) {
      this.activeIndex = 0;
      this.desiredName = name;
      for (let i = 0; i < this.shownCategories.length; i++) {
        if (this.shownCategories[i].name === this.desiredName) {
          this.categoryName = this.shownCategories[i];
          break;
        }
      }
      for (let i = 0; i < this.categoryName.subcategories.length; i++) {
        if (i == 0) {
          this.subCategoryName = this.categoryName.subcategories[i].name;
        }
      }
      let subCategory = this.categoryName.subcategories;
      this.catLength = subCategory.length;
      for (let i = 0; i < subCategory.length; i++) {
        if (subCategory[i].name === this.subCategoryName) {
          this.selectsubCategories = subCategory[i].subcategories;
          break;
        }
      }
    },
    checkMobile() {
      this.isMobile = window.innerWidth <= 1023;
    },
    getSubCategory(subName, index) {
      this.activeIndex = index;
      this.subCategoryName = subName;
      let subCategory = this.categoryName.subcategories;
      subCategory.forEach((el) => {
        if (el.name === this.subCategoryName) this.selectsubCategories = el.subcategories;
      });
    },
    showDropdownlist() {
      this.isDropdownVisible = true;
    },
    showDropdown(name) {
      this.showHover =true;
      this.isDropdownVisible = true;
      this.getName(name);
    },
    hideDropdown() {
      this.hoveredCategory = null;
      this.isDropdownVisible = false;
    },
  }
};
</script>

<style scoped lang="scss">

.trustpilot-micro-review{
  display: none;
  @media (--viewport-tablet) {
    display: inline-block;
    justify-content: end;
    padding-top: 12px;
  }
  @media (--viewport-desktop) {
    right: 117px;
  }
 @media screen and (width: 1024px){
  padding-top: 0 !important;
 }
}
.trustpilot-micro-mobile-review{
  background-color: #22201F;
  @media (--viewport-mobile) {
       display: block;
       padding-top: 6px;
     }
     @media (--viewport-tablet) {
       display: none;
     }
}
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  position: fixed;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  width: 610px;
  padding-top: 10px;
  border-radius: 10px;
}
@media screen and (max-width: 768px) {
  .dropdown-content{
    display: block;
  }
}
.categoryImage {
  border-radius: 10px 10px 0 0;
  border-color: #22201f;
  height: 120px;
  display: flex;
  align-items: center;
  background-image: linear-gradient(90deg,
      #22201f 48%,
      rgba(34, 32, 31, 0.7) 63.02%,
      rgba(34, 32, 31, 0) 100%);

  img {
    position: relative;
    border-radius: 10px 10px 0 0;
  }
}

li.active {
  background: rgba(235, 100, 24, 0.06) !important;
  border-left: 4px solid #eb6418;
  cursor: pointer;
}
.categories-list{
  ul {
    li{
      a {
              color: #22201f;
              white-space: initial;
              display: block;
    width: 100%;
            }
    }
  }
}
p.category-name {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 36px;
  font-feature-settings: "pnum" on, "lnum" on;
  color: #ffffff;
  padding: 0 0 0 30px;
  position: absolute;
}

h1.category-main-heading {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  height: 25px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  padding: 24px 0 16px 30px;
  white-space: initial;
}

.categories-data {
  background: #ffffff;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  color: #22201f;
  width: 100%;
  border-radius: 0 0 10px 10px;
}

.categories-list,
.sub-categories {
  width: 50%;
}

.hoverData {
  border-radius: 10px;
}

.sub-categories {
  background: rgba(235, 100, 24, 0.06);
  border-radius: 0 0 10px 10px;

  ul {
    max-height: 320px;
    height: auto;
    overflow: auto;

    li {
      height: 34px;
      display: flex;
      align-items: center;
      padding: 0 0 0 30px;
      border-bottom: 1px solid #f0e8e3;
      cursor: pointer;
      white-space: initial;

      a {
        color: #22201f;
        white-space: initial;
        &:hover{
          color: #EB6418;
         border-bottom: 1px solid #EB6418 ;
      }
      }
    }
  }
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #999999;
  border-radius: 40px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #999999;
}

li.active {
  .product-ico {
    fill: #eb6418;
  }
}

.product-ico {
  position: absolute;
  width: 20px;
  height: 10px;
  top: 51%;
  right: 0;
  transform: rotate(270deg) translate(-50%, -50%);
  transform-origin: top left;
  fill: var(--color-grey-5);
}

.categories-list {
  ul {
    border-radius: 0 0 10px 10px;
    max-height: 320px;
    height: auto;
    overflow: auto;

    li {
      position: relative;
      border-bottom: 1px solid #ebebeb;
      background: #ffffff;
      height: 34px;
      display: flex;
      align-items: center;
      padding: 0 0 0 30px;
      cursor: pointer;
      white-space: initial;
      a {
              color: #22201f;
              white-space: initial;
              display: block;
    width: 100%;
            }
    }
  }
}

.catalog-navigation__text:hover+.dropdown-content,
.dropdown-content:hover {
  display: block;
}

.catalog-navigation {
  position: relative;
  min-height: 48px;
  background-color: var(--secondary-color-light);
  z-index: -1;
}

.catalog-navigation__wrapper {
  white-space: nowrap;
  overflow: hidden;
  overflow-x: scroll;
  box-sizing: border-box;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}
.right_nav{
  display: flex;
  align-items: center;
}
.catalog-navigation__list {
  position: relative;
  margin: 0 -10px;
  position: relative;

  @media (--viewport-tablet) {
    margin: 0 -15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.catalog-navigation__item {
  display: inline-block;
  position: relative;

  &:hover {
    .catalog-navigation__text {
      color: var(--color-grey-4);
      border-bottom: 3px solid #eb6418;

      &:after {
        content: "";
      }
    }
  }
}

.catalog-navigation__text {
  position: relative;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  height: 48px;
  padding: 5px 15px 5px 33px;
  color: #ffffff;
  font-size: 0.875rem;
  font-weight: bold;
  text-transform: uppercase;
  box-sizing: border-box;

  &:after {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: var(--brand-color);
    transition: 0.3s ease;
    z-index: 998;
  }
}

.catalog-navigation__ico {
  position: absolute;
  top: 50%;
  left: 8px;
  transform: translateY(-50%);
  width: 18px;
  height: 18px;
  box-sizing: border-box;
  fill: #ffffff;
}
.become_a_seller{
  color:var(--brand-color);
  font-weight:600;
  width: auto;
  text-transform: uppercase;
  font-size:.75rem;
  display:none;
  @media (--viewport-desktop) {
    display: block;
    font-size:.875rem;
  }
  @media (--viewport-large-desktop) {
    right:15px;
  }
  &:hover{
    cursor:pointer;
  }
}
.who_we_are{
  color:var(--brand-color);
  font-weight:600;
  font-size:0.8vw;
  font-size:.75rem;
  padding-inline:3px;
  display:none;
  @media (--viewport-desktop) {
    display: inline-block;
    font-size:.875rem;
  }
  @media (--viewport-large-desktop) {
    left:15px;
    padding-inline:15px;
  }
  &:hover{
    cursor:pointer;
  }
}
</style>
