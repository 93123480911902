<template>
  <div class="search" :class="{ 'search--active': isOpen }" v-on-clickaway="closeDropdown">
    <input
      type="text"
      @input="onChange"
      @focus="onFocus"
      v-model="search"
      @keydown.down.prevent="onArrowDown"
      @keydown.up.prevent="onArrowUp"
      @keydown.enter="onEnter"
      class="search__input"
      :placeholder="$t('product-search-bar-place-holder')"
    />

    <button class="search__button search__button--clear" type="button" @click="clear()">
      <svg-icon name="close" class="search__ico search__ico--clear"></svg-icon>
    </button>

    <button class="search__button search__button--search" aria-label="search icon" type="button" @click="onSearchButtonClick()">
      <svg-icon name="search" class="search__ico search__ico--search"></svg-icon>
    </button>

    <div class="search__list" v-if="isOpen">
      <SearchResults :isLoading="isLoading" :results="results" :search="search" :arrowCounter="arrowCounter" @setResult="setResult"/>
    </div>

  </div>
</template>

<script>
import {mixin as clickaway} from 'vue-clickaway';
import {searchBarMixin} from '@/mixins/searchBar';

export default {
  components: {
    SearchResults:() => import('@/components/search/SearchResults')
  },
  data() {
    return {
      isOpen: false,
      arrowCounter: 0
    };
  },
  mixins: [clickaway, searchBarMixin],
  mounted(){
    if(this.$route.path == '/partner/idea-yacht/search'){
      const searchUrl = this.$route.path;
      const searchTerm = this.$route?.query;
      this.onEnter(searchTerm, searchUrl);
    }
  },
  methods: {
    onChange() {
      this.isOpen = true;
      this.arrowCounter = 0;
      this.fetchResults();
    },
    onFocus() {
      this.isOpen = true;
    },
    onArrowDown() {
      if (this.arrowCounter < this.results.length) {
        this.arrowCounter++;
      }
    },
    onArrowUp() {
      if (this.arrowCounter > 1) {
        this.arrowCounter--;
      }
    },
    onEnter(searchTerm = null, searchUrl =null) {
      if (this.results[this.arrowCounter - 1]) {
        this.setResult(this.results[this.arrowCounter - 1]);
        this.isOpen = false;
      } else {
        this.onSearchButtonClick(searchTerm, searchUrl);
      }
      this.arrowCounter = 0;
    },
    onSearchButtonClick(searchTerm, searchUrl = null) {
      this.goToTheSearchPage(searchTerm, searchUrl);
      this.closeDropdown();
    },
    closeDropdown() {
      this.isOpen = false;
      this.arrowCounter = 0;
    },
    clear() {
      this.search = '';
      this.results = [];
    },
    setResult(result) {
      this.search = '';
      this.goToTheResultPage(result);
      this.closeDropdown();
    }
  }
}
</script>

<style scoped lang="scss">
.search {
  position: relative;
  max-width: 590px;
  width: 100%;
}

.search--active {
  .search__input {
    padding-left: 20px;
    padding-right: 85px;
    box-sizing: border-box;
  }

  .search__button--search {
    &:after {
      content: '';
    }
  }

  .search__ico--search {
    fill: var(--brand-color);
  }

  .search__button--clear {
    display: block;
  }
}

.search__input {
  position: relative;
  padding: 10px 51px 10px 16px;
  width: 100%;
  height: 40px;
  font-size: 1rem;
  line-height: 1.25;
  background: #ffffff;
  border-radius: 2px;
  box-sizing: border-box;
  z-index: 1;

  &::placeholder {
    color: var(--color-grey-5);
  }
}

.search__button {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 50%;
  transform: translateY(-50%);
  right: 19px;
  z-index: 1;

  &:after {
    position: absolute;
    top: 0;
    left: -13px;
    width: 1px;
    height: 24px;
    background-color: var(--color-grey-3);
  }
}

.search__button--clear {
  display: none;
  right: 67px;
}

.search__ico {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  width: 18px;
  height: 18px;
  fill: var(--secondary-color-medium);
}

.search__ico--clear {
  width: 14px;
  height: 14px;
  fill: var(--color-grey-4);
}

.search__list {
  position: absolute;
  top: 0;
  left: 0;
  padding: 46px 0 10px;
  margin: 0;
  border: 1px solid #FFFFFF;
  overflow: auto;
  width: 100%;
  background-color: #FFFFFF;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  box-sizing: border-box;

  &:before {
    content: '';
    position: absolute;
    top: 40px;
    left: 20px;
    height: 1px;
    width: calc(100% - 40px);
    background-color: var(--color-grey-3);
  }
}
</style>
