<template>
  <div
    class="header"
    :class="{ 'header--ribbon': isRibbonShown }"
  >
    <BaseRibbon
      :isRibbonShown="isRibbonShown"
      :closeRibbon="closeRibbon"
    />
    <div class="header__wrapper wrapper">
      <div class="header__content">
        <div class="header__box">
          <BaseLogo/>
        </div>

        <div class="header__box header__box--catalog-shortcut">
          <div class="header__catalog">
            <button
              v-if="$route.name === 'catalog-slug' && shownCategories.length === 1"
              @click="goToCategoriesList()"
              type="button"
              class="header__catalog-button button button--small"
            >
              {{ $t('base-header-categories-btn') }}
            </button>
          </div>
        </div>

        <div class="header__box header__box--search">
          <button class="header__button only-mobile" v-if="Mobile" type="button" @click="openSearch">
            {{ $t('base-header-search-btn') }}
          </button>
          <BaseSearch v-if="!Mobile" class="only-tablet-desktop"></BaseSearch>
        </div>

        <div class="header__box">
          <div class="header__actions">
            <BaseHeaderActions />
          </div>
        </div>
      </div>
    </div>
    <CatalogNavigation/>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';

  import BaseSearch from '@/components/search/BaseSearch.vue';
  import BaseLogo from '@/components/base/BaseLogo';
  import BaseRibbon from '@/components/base/BaseRibbon';
  import CatalogNavigation from '@/components/catalog/navigation/CatalogNavigation';
  import debounce from 'lodash/debounce';

  export default {
    components: {
      BaseLogo,
      BaseSearch,
      BaseHeaderActions:() => import('@/components/base/header/BaseHeaderActions.vue'),
      BaseRibbon,
      CatalogNavigation,
    },
    props: {
      isRibbonShown: Boolean,
      closeRibbon: Function,
      isMobile: Boolean
    },
    computed: {
      ...mapGetters({
        shownCategories: 'shared/shownCategories'
      })
    },
    data() {
      return {
        lastScrollPosition: 0,
        scrollValue: 0,
        searchActive: false,
        isScrolled: false,
        Mobile: true
      };
    },
    methods: {
      openSearch() {
        this.$root.$emit('search-bar:open');
      },
      goToCategoriesList() {
        this.$router.push(this.$transformParamsForPort(this.$route, { name: 'catalog-slug', params: { slug: 'engineering' }}));
      },
      checkMobile(){
        this.Mobile = this.isMobile;
        if(window?.innerWidth < 815) this.Mobile = true;
        else this.Mobile = false;
      }
    },
    mounted(){
      this.checkMobile();
      window.addEventListener('resize', debounce(this.checkMobile, 200));
    }
  };
</script>

<style scoped lang="scss">
  .header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: var(--header-height);
    background: var(--secondary-color-medium);
    color: #ffffff;
    z-index: 999;
    transition: transform 0.1s ease-out, background 0.1s ease-out;
    box-sizing: border-box;

    @media (--viewport-tablet) {
      position: fixed;
      height: var(--desktop-header-height);
    }
  }

  .header--ribbon {
    height: var(--header-ribbon-height);

    @media (--viewport-desktop) {
      height: var(--desktop-header-ribbon-height);
    }

  }

  .header__content {
    margin: 0 -14px;
    display: flex;
    align-items: center;
    height: 100%;
  }

  .header__box {
    min-height: 56px;
    display: flex;
    align-items: center;
    padding: 0 14px;
    box-sizing: border-box;

    @media (--viewport-tablet) {
      height: 100%;
    }
  }

  .header__box--search {
    // flex: 1;
    width: 45%;
    display: flex;
    justify-content: center;
    padding: 0;
    color: #000000;

    @media (--viewport-tablet) {
      padding: 10px;
    }
  }

  .header__box--catalog-shortcut {
    display: none;
    position: relative;

    @media (--viewport-tablet) {
      display: flex;
    }
  }

  .header__button {
    padding: 10px 51px 10px 16px;
    width: 100%;
    height: 40px;
    font-size: 1rem;
    line-height: 1.25;
    background: #ffffff;
    border-radius: 2px;
    z-index: 1;
    color: var(--color-grey-5);
    text-align: left;
    box-sizing: border-box;
  }

  .header__catalog {
    height: 100%;
    display: flex;
    align-items: center;

    &:hover {
      .header__catalog-shortcut {
        display: block;
      }
    }
  }

  .header__actions {
    height: 100%;
  }
</style>
