<template>
  <nuxt-link :to="$transformParamsForPort($route, { name: 'index' })" class="base-logo">
    <img class="base-logo__img base-logo--big" src="~/assets/images/logo-full.svg" alt="YACHTNEEDS logo" loading="lazy" height="36" width="185"/>
    <img class="base-logo__img base-logo--small" src="~/assets/images/logo.svg" alt="YACHTNEEDS logo" loading="lazy" height="30" width="36"/>
    <img class="base-logo__img base-logo--empty" src="~/assets/images/logo-small.svg" alt="YACHTNEEDS logo" loading="lazy" height="30" width="36"/>
  </nuxt-link>
</template>

<script>
  export default {
    name: 'BaseLogo'
  }
</script>

<style scoped lang="scss">
  .base-logo {
    position: relative;
    height: 36px;
    display: block;

    &:hover {
      @media (--viewport-desktop) {
        .base-logo--empty {
          animation: rotateLogo 1s linear forwards;
        }
      }
    }
  }

  .base-logo__img {
    height: 100%;
  }

  .base-logo--small {
    width: 36px;
    height: 36px;
    @media (--viewport-tablet) {
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  .base-logo--empty {
    display: none;
    width: 30px;
    @media (--viewport-tablet) {
      display: block;
      position: absolute;
      top: 0;
      left: 3px;
    }
  }

  .base-logo--big {
    display: none;

    @media (--viewport-tablet) {
      display: inline-block;
    }
  }
</style>
